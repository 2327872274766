<template>
  <div class="item-num-container">
    <div class="item-no">Item No: {{itemNo}}</div>
    <div class="qty-container">
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }" hideRequiredMark>
        <a-form-item label="QTY:">
          <a-input-number size="large" autoFocus v-decorator="['qty', { rules: [{ required: true, message: 'Please input QTY' }] }]" />
        </a-form-item>
      </a-form>
    </div>
    <div class="ok-btn">
      <a-button size="large" @click="updateOrderInfo">OK</a-button>
    </div>
  </div>
</template>

<script>
import {
  editCartInfo
} from '@/req-api-common'

const defaultNum = 24
export default {
  name: "item-num",
  data() {
    return {
      itemNo: '',
      form: this.$form.createForm(this, { name: 'qty' }),
      orderInfo: null
    }
  },
  mounted() {
    this.itemNo = localStorage.getItem('itemNo')
    let orderInfo = JSON.parse(localStorage.getItem('orderInfo'))
    !orderInfo && (orderInfo = [])
    const curItem = orderInfo.find(item => item.itemNo === this.itemNo)
    if (curItem) {
      this.form.setFieldsValue({
        qty: curItem.qty || defaultNum
      });
    } else {
      this.form.setFieldsValue({
        qty: defaultNum
      });
      orderInfo.push({
        itemNo: this.itemNo,
        qty: defaultNum
      })
    }
    this.orderInfo = orderInfo
  },
  methods: {
    updateOrderInfo() {
      this.form.validateFields((err, values) => {
        const {qty} = values
        if (err || !qty) {
          return
        }
        const curItem = this.orderInfo.find(item => item.itemNo === this.itemNo)
        curItem.qty = qty
        localStorage.setItem('orderInfo', JSON.stringify(this.orderInfo))
        editCartInfo()
        localStorage.removeItem('itemNo')
        this.$router.push('/scan')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.item-num-container {
  padding: 20vh 30px 0 20vw;

  .item-no {
    margin-right: 10px;
  }

  .qty-container {
    display: flex;
    align-items: flex-end;
    margin-bottom: 30px;

    .ant-form {
      width: 100%;
    }
  }

  .ok-btn {
    .ant-btn {
      width: 100px;
      height: 50px;
      font-size: 30px;
    }
  }
}
</style>